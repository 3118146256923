<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <!-- <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"

              @click=""
            ><i data-feather="plus" /></a> -->
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="alerts"
              :sort-mapping="sortMapping"
              :title="'alerts'"
              :total="alertsTotal"
              :filter-path="'alerts/fetchSent'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <b-dropdown-item @click="showInfoSheet(field), read(field)">
                      <i data-feather="eye" />
                      Read
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="field.user.id == loggedUser.id"
                      class="text-danger"
                      @click="showDeleteModal(field.id)"
                    >
                      <i data-feather="trash" />
                      Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </template>
              <template #info-sheet-item="{item}">
                <div
                  v-if="item.id"
                  class="offcanvas-body offcanvas-body--view"
                >
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Send<br>
                      <strong>{{ item.created_at }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Subject<br>
                      <strong>{{ item.title }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Message<br>
                      <strong>{{ item.message }}</strong>
                    </p>
                  </div>
                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    class="btn btn-dark mb-1 d-grid w-100"
                    @click="$store.dispatch('modals/toggleInfoItemSheet', false)"
                  >Close</a>
                </div>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Title</label>
                  <input
                    v-model="filters.title"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Title: ${$event.target.value}`, value: $event.target.value }, 'title')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Source</label>
                  <v-select
                    v-model="filters.source"
                    label="name"
                    :options="[
                      { name: 'All', value: 0 },
                      { name: 'ICREA', value: true },
                      { name: 'You', value: false },
                    ]"
                    :get-option-key="option => option.name"
                    @input="saveFilter({ customLabel: true, name: `Source: ${$event ? $event.name : ''}`, value: $event }, 'source')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Is read</label>
                  <v-select
                    v-model="filters.is_read"
                    label="name"
                    :options="[
                      { name: 'All', value: 0 },
                      { name: 'Yes', value: true },
                      { name: 'No', value: false },
                    ]"
                    :get-option-key="option => option.name"
                    @input="saveFilter({ customLabel: true, name: `Is read: ${$event ? $event.name : ''}`, value: $event }, 'is_read')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Begin date</label>
                  <br>
                  <date-picker
                    v-model="filters['begin_date']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Begin date: ${$event}`, value: $event }, 'begin_date')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >End date</label>
                  <br>
                  <date-picker
                    v-model="filters['end_date']"
                    format="D MMM Y"
                    class="w-100"
                    value-type="format"
                    @change="saveFilter({ customLabel: true, name: `End date: ${$event}`, value: $event }, 'end_date')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    DatePicker,
  },
  data() {
    return {
      sortedField: 'Title',
      defaultFields: [
        {
          name: 'Title',
          checked: true,
          order: 1,
        },
        {
          name: 'Message',
          checked: true,
          order: 2,
        },
        {
          name: 'Is read',
          checked: true,
          order: 3,
        },
        {
          name: 'Source',
          checked: true,
          order: 4,
        },
        {
          name: 'Type',
          checked: true,
          order: 5,
        },
        {
          name: 'Date',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Title: 'title',
        Message: 'message',
        'Is read': 'read',
        Source: 'source',
        Type: 'type.name',
        Date: 'begin_date',
      },
      title: 'Alerts',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      alerts: 'alerts/alerts',
      alertsTotal: 'alerts/alertsTotal',
      filters: 'filters/filters',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'alerts-table')
    await this.$store.dispatch('alerts/fetchSent', this.filters)
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'alerts-table',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async read(alert) {
      if (!alert.read) {
        await this.$store.dispatch('alerts/read', alert.id)
        this.$store.dispatch('alerts/fetchSent', this.filters).then(() => {
          setTimeout(() => {
            // eslint-disable-next-line no-undef
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 50)
        })
        this.$store.dispatch('alerts/fetchPendingToRead')
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'alerts/fetchSent', url: `${Vue.prototype.$groupUrl}/alerts/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
