var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-content content"},[_c('div',{staticClass:"content-overlay"}),_c('div',{staticClass:"header-navbar-shadow"}),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-header row"},[_c('div',{staticClass:"content-header-left col-md-9 col-12 mb-2"},[_c('div',{staticClass:"row breadcrumbs-top profile__avatar"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"content-header-title mb-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])])]),_vm._m(0)]),_c('div',{staticClass:"content-body content-body--senior-call"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('CompleteTable',{attrs:{"sorted-field":_vm.sortedField,"data":_vm.alerts,"sort-mapping":_vm.sortMapping,"title":'alerts',"total":_vm.alertsTotal,"filter-path":'alerts/fetchSent'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var field = ref.field;
var showInfoSheet = ref.showInfoSheet;
return [_c('td',{staticClass:"text-end"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","toggle-class":"p-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{attrs:{"data-feather":"more-vertical"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){showInfoSheet(field), _vm.read(field)}}},[_c('i',{attrs:{"data-feather":"eye"}}),_vm._v(" Read ")]),(field.user.id == _vm.loggedUser.id)?_c('b-dropdown-item',{staticClass:"text-danger",on:{"click":function($event){return _vm.showDeleteModal(field.id)}}},[_c('i',{attrs:{"data-feather":"trash"}}),_vm._v(" Delete ")]):_vm._e()],1)],1)]}},{key:"info-sheet-item",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('div',{staticClass:"offcanvas-body offcanvas-body--view"},[_c('div',{staticClass:"mb-1 offcanvas-body--view--title"},[_c('p',[_vm._v("Send"),_c('br'),_c('strong',[_vm._v(_vm._s(item.created_at))])])]),_c('hr'),_c('div',{staticClass:"mb-1 offcanvas-body--view--title"},[_c('p',[_vm._v("Subject"),_c('br'),_c('strong',[_vm._v(_vm._s(item.title))])])]),_c('div',{staticClass:"mb-1"},[_c('p',[_vm._v("Message"),_c('br'),_c('strong',[_vm._v(_vm._s(item.message))])])])]):_vm._e(),_c('div',{staticClass:"offcanvas-footer mt-auto"},[_c('a',{staticClass:"btn btn-dark mb-1 d-grid w-100",on:{"click":function($event){return _vm.$store.dispatch('modals/toggleInfoItemSheet', false)}}},[_vm._v("Close")])])]}},{key:"customfilters",fn:function(){return [_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"nameseniorcall"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.title),expression:"filters.title"}],staticClass:"form-control",attrs:{"type":"text","name":"nameseniorcall"},domProps:{"value":(_vm.filters.title)},on:{"change":function($event){return _vm.saveFilter({ customLabel: true, name: ("Title: " + ($event.target.value)), value: $event.target.value }, 'title')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "title", $event.target.value)}}})]),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"nameseniorcall"}},[_vm._v("Source")]),_c('v-select',{attrs:{"label":"name","options":[
                    { name: 'All', value: 0 },
                    { name: 'ICREA', value: true },
                    { name: 'You', value: false } ],"get-option-key":function (option) { return option.name; }},on:{"input":function($event){return _vm.saveFilter({ customLabel: true, name: ("Source: " + ($event ? $event.name : '')), value: $event }, 'source')}},model:{value:(_vm.filters.source),callback:function ($$v) {_vm.$set(_vm.filters, "source", $$v)},expression:"filters.source"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"nameseniorcall"}},[_vm._v("Is read")]),_c('v-select',{attrs:{"label":"name","options":[
                    { name: 'All', value: 0 },
                    { name: 'Yes', value: true },
                    { name: 'No', value: false } ],"get-option-key":function (option) { return option.name; }},on:{"input":function($event){return _vm.saveFilter({ customLabel: true, name: ("Is read: " + ($event ? $event.name : '')), value: $event }, 'is_read')}},model:{value:(_vm.filters.is_read),callback:function ($$v) {_vm.$set(_vm.filters, "is_read", $$v)},expression:"filters.is_read"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"nameseniorcall"}},[_vm._v("Begin date")]),_c('br'),_c('date-picker',{staticClass:"w-100",attrs:{"format":"D MMM Y","value-type":"format"},on:{"change":function($event){return _vm.saveFilter({ customLabel: true, name: ("Begin date: " + $event), value: $event }, 'begin_date')}},model:{value:(_vm.filters['begin_date']),callback:function ($$v) {_vm.$set(_vm.filters, 'begin_date', $$v)},expression:"filters['begin_date']"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"nameseniorcall"}},[_vm._v("End date")]),_c('br'),_c('date-picker',{staticClass:"w-100",attrs:{"format":"D MMM Y","value-type":"format"},on:{"change":function($event){return _vm.saveFilter({ customLabel: true, name: ("End date: " + $event), value: $event }, 'end_date')}},model:{value:(_vm.filters['end_date']),callback:function ($$v) {_vm.$set(_vm.filters, 'end_date', $$v)},expression:"filters['end_date']"}})],1)]},proxy:true}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-header-right text-md-end col-md-3 col-12 d-md-block d-none"},[_c('div',{staticClass:"mb-1 breadcrumb-right"})])}]

export { render, staticRenderFns }